//CLIENT_ID and TENANT_ID both extracted from constants file (externailsing them - not in source code repository)
import Utils from "./Common/Utils";

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
let redirectUri = isLocalhost? Utils.getEnvVars().localhost_call_back_url : Utils.getEnvVars().call_back_url;


export const msalConfig = {
  auth: {
    clientId: Utils.getEnvVars().clientId,
    authority:
      "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580/",
    redirectUri: redirectUri
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
};
